import React, { useState } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { ReadOnlyField } from "../";
import styles from "./order-details.module.css";
import { Select } from "@chakra-ui/select";
import { useFormContext } from "react-hook-form";
import moment from "moment";

const STATUS = [
  { value: "cancelled", label: "Cancelled" },
  { value: "completed", label: "Completed" },
  { value: "on-hold", label: "On Hold" },
  { value: "refunded", label: "Refunded" },
  { value: "processing", label: "Processing" },
  { value: "failed", label: "Failed" },
];

const OrderDetails = (props) => {
  const {
    dateCreated,
    shippingTotal,
    discountTotal,
    paymentMethodTitle,
    lineItems,
    status: statusProp,
    id,
    isLoading,
    isEditable,
  } = props;
  const { register } = useFormContext();
  const [status, setstatus] = useState(statusProp);

  return (
    <div>
      <div>
        <button
          type="button"
          className="w-40 border-2 border-primary rounded-2xl px-5 py-1 text-primary"
        >
          <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="mr-2" />
          View Order
        </button>
      </div>
      <div>
        <div className="text-secondary">Status</div>
        <Select
          color="#100eff"
          icon=""
          defaultValue={status}
          onChange={(e) => setstatus(e.target.value)}
          {...register("status")}
          disabled={!isEditable}
        >
          {STATUS.map(({ value, label }) => (
            <option value={value}>{label}</option>
          ))}
        </Select>
      </div>
      <div className={styles.details}>
        <ReadOnlyField
          label="Order Date"
          value={moment(dateCreated).format("DD/MM/YYYY (hh:mm a)")}
        />
        <ReadOnlyField
          label="Shipping Method"
          value={
            parseFloat(shippingTotal) === 0 ? "Free!" : `$${shippingTotal}`
          }
        />
        <ReadOnlyField
          label="Coupon"
          value={
            parseFloat(discountTotal) === 0
              ? "No Discount"
              : `$${discountTotal}`
          }
        />
        <ReadOnlyField label="Payment Method" value={paymentMethodTitle} />
      </div>
      <div>
        <div className="text-secondary">Items</div>
        <hr />
        <table className="w-full">
          <thead className="text-secondary text-left">
            <tr>
              <th>Qty</th>
              <th>Item</th>
              <th>Subtotal</th>
            </tr>
          </thead>
          <tbody className="text-primary">
            {lineItems.map(({ quantity, name, price }, index) => (
              <tr
                key={name}
                className={`align-baseline ${
                  index % 2 === 0 ? "bg-[#f9f9fc]" : ""
                }`}
              >
                <td className="py-2">{quantity}</td>
                <td className="py-2 w-8/12">{name}</td>
                <td className="py-2">${price}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {isEditable && (
        <div className="flex justify-center my-4">
          <button
            className={`px-3 py-1 items-center rounded-md text-center w-full ${
              isLoading ? "bg-gray-500 text-black" : "bg-primary text-white"
            }`}
            type="submit"
            disabled={isLoading}
          >
            Update
          </button>
        </div>
      )}
    </div>
  );
};

OrderDetails.propTypes = {
  number: PropTypes.string,
  status: PropTypes.string,
  dateCreated: PropTypes.string,
  shippingTotal: PropTypes.string,
  discountTotal: PropTypes.string,
  lineItems: PropTypes.array,
  paymentMethodTitle: PropTypes.string,
};

export default OrderDetails;
