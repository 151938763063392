import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { faCartPlus, faArrowsRotate } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CustomerCard = (props) => {
  const {
    firstName = "",
    lastName = "",
    id = "",
    email = "",
    phone = "",
    address1 = "",
    address2 = "",
    city = "",
    state = "",
    postcode = "",
    country = "",
  } = props;
  const lastFetchedDate = useMemo(
    () => new Date(Date.now()).toLocaleString("en-US"),
    []
  );

  return (
    <div className="p-5">
      <div>
        <div className="flex">
          <div className="text-sm text-secondary grow">CUSTOMER</div>
          <div className="text-secondary">
            {lastFetchedDate}
            <FontAwesomeIcon
              className="ml-2 cursor-pointer"
              icon={faArrowsRotate}
              onClick={props.fetchOrders}
            />
          </div>
        </div>
        <div className="text-xl text-primary">{`${firstName} ${lastName}`}</div>
        {/* <div className="text-secondary">
          <FontAwesomeIcon icon={faCartPlus} className="mr-2" />
          Add new order
        </div> */}
        <div className="text-primary">Customer ID: {id}</div>
        <div className="text-primary">{email}</div>
        <div className="text-primary">{phone ? `+${phone}` : ""}</div>
      </div>
      <div>
        <div className="text-sm text-secondary font-bold">PRIMARY ADDRESS</div>
        <div className="text-primary">{address1}</div>
        <div className="text-primary">{address2}</div>
        <div className="text-primary">{city}</div>
        <div className="text-primary">{state}</div>
        <div className="text-primary">{postcode}</div>
        <div className="text-primary">{country}</div>
      </div>
    </div>
  );
};

CustomerCard.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  id: PropTypes.number,
  email: PropTypes.string,
  phone: PropTypes.string,
  address1: PropTypes.string,
  address2: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  postcode: PropTypes.string,
  country: PropTypes.string,
};

export default CustomerCard;
