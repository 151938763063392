import React, { useState } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card } from "../";
import { faChevronCircleDown } from "@fortawesome/free-solid-svg-icons";

const Collapsible = ({ className, open = false, children, number, status }) => {
  const [expanded, setExpanded] = useState(open);
  const handleTogglePanel = () => {
    setExpanded(!expanded);
  };

  return (
    <Card className="px-5">
      <div className="flex">
        <button className="flex w-full py-2" onClick={handleTogglePanel}>
          <div className="flex flex-row grow items-center">
            <FontAwesomeIcon
              icon={faChevronCircleDown}
              className={`mr-2 text-primary ${
                expanded ? "" : "-rotate-90"
              } transition`}
            />
            <div className="text-lg text-primary">Order #{number}</div>
          </div>
          <div className="bg-primary text-white px-3 py-1 items-center rounded-md">
            {status}
          </div>
        </button>
      </div>

      <div
        className={`${
          expanded ? "h-auto opacity-100" : "h-0 opacity-0 overflow-hidden"
        } block transition pt-2`}
      >
        {children}
      </div>
    </Card>
  );
};

Collapsible.propTypes = {
  number: PropTypes.string,
  status: PropTypes.string,
};
export default Collapsible;
