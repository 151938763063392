import * as React from "react";
import { Routes, Route } from "react-router-dom";

import CrispWidget from "./containers/crisp/widget";
import CrispSettings from "./containers/crisp/settings";

import FrontWidget from "./containers/front";

function App() {
  return (
    <Routes>
      <Route path="/crisp-widget" element={<CrispWidget />} />
      <Route path="/crisp-settings" element={<CrispSettings />} />

      <Route path="/front-widget" element={<FrontWidget />} />
    </Routes>
  );
}

export default App;
