import PropTypes from "prop-types";
import { FormProvider, useForm } from "react-hook-form";
import Collapsible from "../Collapsible/Collapsible";
import OrderDetails from "../OrderDetails/OrderDetails";
import CustomerCard from "../CustomerCard/CustomerCardEditable";
import { api } from "../../api";
import { useState } from "react";

const Orders = ({ list, fetchOrders }) => {
  const form = useForm();

  return (
    <div>
      {list.map((order) => {
        const [isEditable, setisEditable] = useState(false);
        const [isSubmitting, setisSubmitting] = useState(false);

        const handleSubmit = async (data) => {
          setisSubmitting(true);
          try {
            const params = new URLSearchParams(window.location.search);
            const website_id =
              params.get("website_id") ||
              "3de0c41d-a397-4584-82ae-9d5eee3c2b80";
            const token =
              params.getAll("token").find((val) => val !== "") ||
              "e77e2e1a-886f-4fda-a53a-db55d342a173";

            await api.put(
              `/orders/${order.id}`,
              {
                status: data.status,
                shipping: data.shipping,
              },
              {
                headers: {
                  "OI-Website-ID": website_id,
                  "OI-Source-ID": "crisp",
                  "OI-Crisp-Token": token,
                },
              }
            );

            await fetchOrders();
          } catch (error) {
            console.error("Error", error);
          }
          setisEditable(false);
          setisSubmitting(false);
        };

        return (
          <Collapsible key={order.number} {...order}>
            <form onSubmit={form.handleSubmit(handleSubmit)}>
              <FormProvider {...form}>
                <CustomerCard
                  {...order.shipping}
                  isEditable={isEditable}
                  setisEditable={setisEditable}
                />
                <OrderDetails
                  {...order}
                  key={order.number}
                  isLoading={isSubmitting}
                  isEditable={isEditable}
                />
              </FormProvider>
            </form>
          </Collapsible>
        );
      })}
    </div>
  );
};

Orders.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      number: PropTypes.string,
      status: PropTypes.string,
      date: PropTypes.string,
      shippingMethod: PropTypes.string,
      coupon: PropTypes.string,
      items: PropTypes.array,
      paymentMethod: PropTypes.string,
    })
  ),
};

export default Orders;
