import React from 'react';
import classNames from 'classnames';
import styles from './card.module.css';

const Card = ({ className, children }) => (
  <div className={classNames([styles.card], [className])}>
    {children}
  </div>
);

export default Card;