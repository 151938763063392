import React from "react";

const ReadOnlyField = ({ className, label, value }) => {
  return (
    <div className={className}>
      <div className="text-secondary">{label}</div>
      <div className="text-primary">{value}</div>
    </div>
  );
};

export default ReadOnlyField;
