import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import {
  faCartPlus,
  faArrowsRotate,
  faEdit,
  faCancel,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Input } from "@chakra-ui/input";
import { FormControl, FormLabel, Grid, SimpleGrid } from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";

const CustomerCard = (props) => {
  const {
    firstName = "",
    lastName = "",
    address1 = "",
    address2 = "",
    city = "",
    state = "",
    postcode = "",
    country = "",
    isEditable,
    setisEditable,
  } = props;
  const { register } = useFormContext();
  const lastFetchedDate = useMemo(
    () => new Date(Date.now()).toLocaleString("en-US"),
    []
  );

  return (
    <div>
      <div>
        <div className="flex">
          <div className="text-sm text-secondary grow">
            Shipping Information
          </div>
          <div className="text-secondary">
            <button
              type="button"
              onClick={() => setisEditable((prev) => !prev)}
            >
              {isEditable ? "Cancel" : "Edit"}
            </button>
            <FontAwesomeIcon
              className="ml-2"
              icon={isEditable ? faCancel : faEdit}
            />
          </div>
        </div>
      </div>
      {isEditable ? (
        <form>
          <SimpleGrid columns={2}>
            <FormControl>
              <FormLabel className="text-secondary">First Name</FormLabel>
              <Input
                defaultValue={firstName}
                colorScheme="blue"
                {...register("shipping.firstName")}
              />
            </FormControl>
            <FormControl>
              <FormLabel className="text-secondary">Last Name</FormLabel>
              <Input
                defaultValue={lastName}
                {...register("shipping.lastName")}
              />
            </FormControl>
            <FormControl>
              <FormLabel className="text-secondary">Address Line 1</FormLabel>
              <Input
                defaultValue={address1}
                {...register("shipping.address1")}
              />
            </FormControl>
            <FormControl>
              <FormLabel className="text-secondary">Address Line 2</FormLabel>
              <Input
                defaultValue={address2}
                {...register("shipping.address2")}
              />
            </FormControl>
            <FormControl>
              <FormLabel className="text-secondary">City</FormLabel>
              <Input defaultValue={city} {...register("shipping.city")} />
            </FormControl>
            <FormControl>
              <FormLabel className="text-secondary">State</FormLabel>
              <Input defaultValue={state} {...register("shipping.state")} />
            </FormControl>
            <FormControl>
              <FormLabel className="text-secondary">Post Code</FormLabel>
              <Input
                defaultValue={postcode}
                {...register("shipping.postcode")}
              />
            </FormControl>
            <FormControl>
              <FormLabel className="text-secondary">Country</FormLabel>
              <Input defaultValue={country} {...register("shipping.country")} />
            </FormControl>
          </SimpleGrid>
        </form>
      ) : (
        <div>
          <div className="text-xl text-primary">{`${firstName} ${lastName}`}</div>
          <div className="text-sm text-secondary font-bold">
            PRIMARY ADDRESS
          </div>
          <div className="text-primary">{address1}</div>
          <div className="text-primary">{address2}</div>
          <div className="text-primary">{city}</div>
          <div className="text-primary">{state}</div>
          <div className="text-primary">{postcode}</div>
          <div className="text-primary">{country}</div>
        </div>
      )}
    </div>
  );
};

CustomerCard.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  id: PropTypes.number,
  email: PropTypes.string,
  phone: PropTypes.string,
  address1: PropTypes.string,
  address2: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  postcode: PropTypes.string,
  country: PropTypes.string,
};

export default CustomerCard;
