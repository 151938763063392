import React from 'react';
import { FrontContextProvider } from './frontContext';

import Widget from './widget';

const FrontContainer = () => {
  return (
    <FrontContextProvider>
      <Widget />
    </FrontContextProvider>
  );
};

export default FrontContainer;
