import { faCircleInfo, faPlug } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { api } from "../../../api";

function Settings() {
  const [searchParams] = useSearchParams();
  const [token, setToken] = useState("");
  const [error, setError] = useState("");
  const [successMessage, setsuccessMessage] = useState("");
  const [isSubmitting, setisSubmitting] = useState(false);
  const websiteId = searchParams.get("website_id") || "no-website-id";
  const crispToken = searchParams.get("token") || "no-token";

  const handleToken = (ev) => {
    const val = ev.target.value;
    setToken(val);
  };

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    try {
      setisSubmitting(true);
      setError("");
      await api.put(
        `/crisp/settings`,
        {
          websiteId,
        },
        {
          headers: {
            "OI-License-Key": ev.target.token.value,
            "OI-Store-ID": ev.target.storeId.value,
            "OI-Crisp-Token": crispToken,
          },
        }
      );
      setsuccessMessage("Successfully connected!");
    } catch (error) {
      console.error("error", error);
      setError("Invalid License key or Store id");
    }
    setisSubmitting(false);
  };

  return (
    <div className="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 w-9/12 border">
      <div className="flex p-3 border-b">
        <div className="grow font-bold">Order Insight plugin</div>
        <div>
          <FontAwesomeIcon className="mr-2 text-secondary" icon={faPlug} />
          Connect Crisp and Order Insight
        </div>
      </div>
      <div className="flex justify-center p-5 text-center">
        <form onSubmit={handleSubmit}>
          <div className="pb-3">
            Provide your Order Insight License Key and Store ID
          </div>
          <div className="pb-3">
            This will connect Crisp to your Order Insight website.&nbsp;
            <a href="https://orderinsight.io/product/free-plan/">
              <FontAwesomeIcon
                className="mr-2 text-primary text-lg tooltip"
                icon={faCircleInfo}
                title="Click me for more info"
              />
            </a>
          </div>
          <div className="pb-3 flex flex-col gap-2 items-center">
            {error && <p className="text-red-500">{error}</p>}
            {successMessage && (
              <p className="text-green-500">{successMessage}</p>
            )}
            <input
              id="token"
              name="token"
              type="text"
              placeholder="Add license key here"
              onChange={handleToken}
              className="border-black border rounded p-2 w-60"
              required
            />
            <input
              id="storeId"
              name="storeId"
              type="text"
              placeholder="Add store ID here"
              onChange={handleToken}
              className="border-black border rounded p-2 w-60"
              required
            />
          </div>
          <button
            type="submit"
            className={`${
              isSubmitting ? "bg-secondary" : "bg-primary text-white"
            } p-3 rounded`}
            disabled={isSubmitting}
          >
            Connect to Order Insight
          </button>
        </form>
      </div>
    </div>
  );
}

export default Settings;
