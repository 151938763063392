import React, { useEffect } from 'react';
import { useFrontContext } from '../frontContext';

const FrontApp = () => {
  const context = useFrontContext();
console.log("------context--", context);
  const params = new URLSearchParams(window.location.search);
  const auth = params.get('auth_secret');
  const email = params.get('email');
  const token = params.get('token');

  useEffect(() => {
    if (context?.type === 'singleConversation') {
      const { recipient: { handle, type }} = context.conversation;
      console.log("~~~~~~~~~~~", handle, type);
      if (type === "email") {
        fetch(`/api/front/orders?auth=${auth}&email=${handle}`)
          .then(response => response.json())
          .then(data => console.log(data))
      }
    }
  }, [context, auth, email]);

  if (!context) {
    return (
      <div className="App">
        <p>Waiting to connect to the Front context.</p>
      </div>
    );
  }

  switch(context.type) {
    case 'noConversation':
      return (
        <div className="App">
          <p>No conversation selected. Select a conversation to use this plugin.</p>
        </div>
      );
    case 'singleConversation':
      return (
        <div className="App">
          App
        </div>
      );
    case 'multiConversations':
      return (
        <div className="App">
          <p>Multiple conversations selected. Select only one conversation to use this plugin.</p>
        </div>
      );
    default:
      console.error(`Unsupported context type: ${context.type}`);
      break;
  };
};

export default FrontApp;