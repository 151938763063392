/* global $crisp */
import React, { useEffect, useState } from "react";
import { CustomerCard, Orders } from "../../../components";
import "./widget.css";
import { api } from "../../../api";
import { Loader } from "../../../components/Loader/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRotate } from "@fortawesome/free-solid-svg-icons";

const mockResponse = {
  customer: {
    id: 6,
    firstName: "John",
    lastName: "Doe",
    email: "john.doe@example.com",
    address: {
      address1: "969 Market",
      address2: "",
      city: "San Francisco",
      state: "CA",
      postcode: "94103",
      country: "US",
    },
  },
  orders: [
    {
      id: 37,
      number: "37",
      orderKey: "wc_order_9cmdBc61WcDPo",
      status: "completed",
      currency: "AUD",
      dateCreated: "2022-10-07T10:16:56",
      dateCreatedGmt: "2022-10-07T10:16:56",
      dateModified: "2022-10-07T10:52:40",
      dateModifedGmt: "2022-10-07T10:52:40",
      discountTotal: "0.00",
      discountTax: "0.00",
      shippingTotal: "0.00",
      shippingTax: "0.00",
      total: "70.00",
      totalTax: "0.00",
      shipping: {
        firstName: "John",
        lastName: "Doe",
        company: "",
        address1: "969 Market",
        address2: "",
        city: "San Francisco",
        state: "CA",
        postcode: "94103",
        country: "US",
      },
      paymentMethodTitle: "Cash on Delivery",
      lineItems: [
        {
          id: 5,
          name: "Apple",
          productId: 25,
          quantity: 1,
          subtotal: "70.00",
          subtotalTax: "0.00",
          total: "70.00",
          totalTax: "0.00",
          price: 70,
        },
      ],
    },
    {
      id: 38,
      number: "38",
      orderKey: "wc_order_9cmdBc61WcDPo",
      status: "pending",
      currency: "AUD",
      dateCreated: "2022-10-07T10:16:56",
      dateCreatedGmt: "2022-10-07T10:16:56",
      dateModified: "2022-10-07T10:52:40",
      dateModifedGmt: "2022-10-07T10:52:40",
      discountTotal: "2.00",
      discountTax: "0.00",
      shippingTotal: "1.00",
      shippingTax: "0.00",
      total: "70.00",
      totalTax: "0.00",
      shipping: {
        firstName: "John",
        lastName: "Doe",
        company: "",
        address1: "969 Market",
        address2: "",
        city: "San Francisco",
        state: "CA",
        postcode: "94103",
        country: "US",
      },
      paymentMethodTitle: "Direct Bank Transfer",
      lineItems: [
        {
          quantity: 1,
          name: "Beanie with Long Name Long Long Name Logo",
          price: 18,
        },
        {
          quantity: 1,
          name: "Hoodie with Logo",
          price: 90,
        },
        {
          quantity: 1,
          name: "T-Shirt",
          price: 126,
        },
        {
          quantity: 1,
          name: "V-Neck T-Shirt Green",
          price: 60,
        },
        {
          quantity: 1,
          name: "Logo Collection",
          price: 18,
        },
      ],
    },
  ],
};

function Widget() {
  const params = new URLSearchParams(window.location.search);
  const website_id =
    params.get("website_id") || "2febc7ed-f14c-46c7-bb8b-6cb3b23c7eb9";
  const email = params.get("email") || "john@madeneat.com.au";
  const token =
    params.getAll("token").find((val) => val !== "") ||
    "e77e2e1a-886f-4fda-a53a-db55d342a173";

  const [customer, setCustomer] = useState({});
  const [address, setAddress] = useState({});
  const [orders, setOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchOrders = async () => {
    setIsLoading(true);
    const { data = mockResponse } = await api.get(
      `/orders?source=crisp&email=${email}&websiteId=${website_id}`,
      {
        headers: {
          "OI-Crisp-Token": token,
        },
      }
    );
    setIsLoading(false);
    const { customer, orders } = data;
    setCustomer(customer);
    setAddress(customer?.address || "");
    setOrders(orders);
  };

  useEffect(() => {
    fetchOrders();
  }, [website_id, email]);

  useEffect(() => {
    $crisp.setHeight(800);
  }, []);

  return (
    <div className="c-widget" style={{ height: "24em" }}>
      {isLoading ? (
        <Loader />
      ) : customer && address && orders.length ? (
        <>
          <section className="c-widget-section">
            <CustomerCard
              {...customer}
              {...address}
              fetchOrders={fetchOrders}
            />
          </section>

          <section className="c-widget-section">
            <div className="bg-[#f9f9fc] px-5 py-2 text-secondary">
              ORDERS&nbsp;({orders.length})
            </div>
            <Orders list={orders} fetchOrders={fetchOrders} />
          </section>
        </>
      ) : (
        <div className="flex align justify-center flex-col">
          <div className="text-xl text-primary text-center">
            No orders found
          </div>
          <button className="text-base underline" onClick={fetchOrders}>
            Retry?
            <FontAwesomeIcon className="ml-2" icon={faArrowsRotate} />
          </button>
        </div>
      )}
    </div>
  );
}

export default Widget;

/**

{
	"version": "1.0",
	"url": "https://orderinsight.staging67.com/public/widget.html?email={conversation.meta.email}&name={conversation.meta.nickname}"
}
https://yourdomain.com/wp-json/wc/v3/customers?email=xxxxxxx@gmail.com
 */
